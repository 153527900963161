import React from "react";
import { Col, Container, Row } from "reactstrap";

import img01 from "../assets/images/feature/img-01.png";
import img02 from "../assets/images/feature/img-02.png";
import img03 from "../assets/images/feature/img-03.png";
import img04 from "../assets/images/feature/img-04.png";
import img05 from "../assets/images/feature/img-05.png";
import img06 from "../assets/images/feature/img-06.png";

const ServicesCard = (props) => {
  return (
    <>
      <Col lg={4} md={6}>
        <div
          className={
            props.isShadow
              ? "service-box text-center mt-4 box-shadow"
              : "service-box text-center mt-4"
          }
        >
          <img src={props.imgsrc} alt="" className="img-fluid" />
          <h5 className="fs-18 mt-4">{props.title}</h5>
          <div className="lighlight-border mt-3"></div>
          <p className="text-muted mt-3">{props.caption}</p>
        </div>
      </Col>
      {/* <!--end col--> */}
    </>
  );
};

// Services data

const ServicesData = [
  {
    imgsrc: img03,
    title: "Generative AI Integrations",
    caption:
      "Unleashing AI's potential to revolutionize your business with intelligent, data-driven solutions. Elevate efficiency and innovation with advanced, tailor-made AI models.",
    isShadow: true,
  },
  {
    imgsrc: img03,
    title: "AI-Driven Data Analytics",
    caption:
      "Unlock the power of your data with advanced analytics. Drive smarter decisions for your business with actionable insights and predictive models.",
    isShadow: true,
  },
  {
    imgsrc: img02,
    title: "Mobile App Development",
    caption:
      "Transforming ideas into high-performance mobile apps that engage users and drive growth. Deliver exceptional experiences across platforms with intuitive designs.",
    isShadow: true,
  },
  {
    imgsrc: img01,
    title: "Web development",
    caption:
      "Creating dynamic, scalable websites with flawless functionality and stunning design. Elevating your online presence with secure, responsive, and engaging web solutions.",
    isShadow: true,
  },
  {
    imgsrc: img04,
    title: "Cloud & DevOps",
    caption:
      "Streamlining your operations with cutting-edge cloud solutions and robust DevOps practices. Enhance scalability, security, and efficiency with our end-to-end services.",
    isShadow: true,
  },
  {
    imgsrc: img04,
    title: "Technical Consultancy",
    caption:
      "Guiding your tech projects with expert insights. Strengthen your systems with optimized architecture, performance enhancements, and security best practices.",
    isShadow: true,
  },
];

const Services = () => {
  return (
    <>
      <section className="section" id="services">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="header-title text-center">
                <p className="text-uppercase text-muted mb-2">
                  Services We're Provided
                </p>
                <h3>We do all Creative Services</h3>
                <div className="title-border mt-3"></div>
                <p className="text-muted mt-3">
                  At Corvix Systems, we craft transformative tech solutions
                  tailored to your business. From innovative mobile apps to AI
                  integration and cloud optimization, we’re committed to driving
                  your success with creativity and precision. Explore our
                  services to see how we turn challenges into opportunities.
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            {/* Services Component Start */}

            {ServicesData.map((value, idx) => (
              <ServicesCard
                key={idx}
                imgsrc={value.imgsrc}
                title={value.title}
                caption={value.caption}
                isShadow={value.isShadow}
              />
            ))}

            {/* Services Component End */}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Services;
