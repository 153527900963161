import React from "react";
import NavBar from "../../Layout/Navbar";
import Section from "./Section";

import About from "../../components/About";
import BackToTop from "../../components/BackToTop";
import Blog from "../../components/Blog";
import Contact from "../../components/Contact";
import Counter from "../../components/Counter";
import CTA from "../../components/CTA";
import Footer from "../../Layout/Footer";
import FooterAlt from "../../Layout/FooterAlt";
import Portfolio from "../../components/Portfolio";
import Pricing from "../../components/Pricing";
import Process from "../../components/Process";
import Services from "../../components/Services";
import Team from "../../components/Team";
import Testimonial from "../../components/Testimonial";

const Layout4 = () => {
  return (
    <React.Fragment>
      <NavBar isDark={true} />
      {/* import Section */}
      <Section />
      <Services />
      <About />
      <Process />
      {/* <Counter /> */}
      {/* <Testimonial /> */}
      {/* <Team /> */}
      {/* <Portfolio /> */}
      {/* <Pricing /> */}
      {/* <Blog /> */}
      {/* <CTA /> */}
      <Contact />
      <Footer />
      <FooterAlt />
      <BackToTop />
    </React.Fragment>
  );
};

export default Layout4;
